/* You can add global styles to this file, and also import other style files */

@import "./assets/scss/app.scss";
:root {
    --theme-deafult: #013d77;
    --theme-secondary: #1b9e5e;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

a {
    color: #000;
    text-decoration: none;
    letter-spacing: 0px;
}

.landing-page .navbar-nav .nav-item .nav-link {
    letter-spacing: 0px;
    font-family: 'Roboto';
}

.page-wrapper .cuba-demo-section .cuba-demo-content .couting h2 {
    color: #013d77;
    font-weight: 500;
    font-size: calc(35px + (70 - 35) * ((100vw - 300px) / (1920 - 300)));
    background: -webkit-linear-gradient(#013d77, #0d6efd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    line-height: 1;
}

.page-wrapper .cuba-demo-content p {
    font-size: 14px;
    color: #080707;
}

.page-wrapper .cuba-demo-section .cuba-demo-content .couting h2 {
    color: #013d77;
    font-weight: 700;
    font-size: 20px;
    background: -webkit-linear-gradient(#013d77, #0d6efd);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    line-height: 3;
}

.layout h5 {
    margin-bottom: 20px;
    opacity: .8;
    letter-spacing: 0;
}

.framworks-list .box {
    display: inline-block;
    background-color: #fff;
    padding: 40px 10px;
    margin-bottom: 30px;
    border-radius: 25px;
    -webkit-box-shadow: 0 0 37px rgb(8 21 66 / 5%);
    box-shadow: 0 0 37px rgb(8 21 66 / 5%);
    margin: 10px;
    height: 185px;
    width: 185px;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

iframe.embed-responsive-item.mb-4 {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    overflow: hidden;
}

.btn-lg,
.btn-group-lg>.btn {
    padding: 0.5rem 1rem;
    font-size: 15px;
    border-radius: 2rem;
}

.landing-slider h4 {
    position: relative;
    font-size: 20px;
    color: #003e71;
    text-transform: capitalize;
}

div#landing {
    top: 232px;
    height: 57%;
    position: absolute;
    width: 4px;
    background-color: #ffffff;
    margin: 0 auto;
    left: 7px;
    z-index: 1;
}

.app_bg {
    background-color: #f3f7fd;
}

.framworks .nav-item a {
    padding: 15px 30px;
    background-color: #f3f7fd;
    border-radius: 50px !important;
}

.card-absolute .card-header {
    position: absolute;
    top: -20px;
    left: 15px;
    border-radius: 1.5rem;
    padding: 10px 15px;
}

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-radius: 1.5rem;
    outline: 0;
    padding: 15px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    font-size: 1rem;
    border-radius: 2rem;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.img-fluid {
    max-width: 70%;
    height: auto;
}

.page-wrapper .sidebar-main-title>div {
    background-color: var(--sidebar-color-default);
    padding: 15px 17px;
    border-radius: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.login-card .login-main {
    width: 450px;
    padding: 40px;
    border-radius: 20px;
    -webkit-box-shadow: 30px 30px 33px rgb(119 119 119 / 25%);
    box-shadow: 30px 30px 33px rgb(119 119 119 / 25%);
    margin: 0 auto;
    background-color: #fff;
}

.modal-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 0px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

.page-wrapper .sidebar-main-title h6 {
    color: #013d77;
    margin-bottom: 5px;
    letter-spacing: 0.4px;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active span {
    color: #013d77;
    transition: all 0.3s ease;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: #013d77;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
    color: #013d77;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links>li .sidebar-link.active svg {
    color: #013d77;
    transition: all 0.3s ease;
}

* a {
    color: #013d77;
    text-decoration: none;
    letter-spacing: 1px;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    font-size: 13px;
    border-radius: 2rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
    background-color: #013d77 !important;
    border-color: #013d77 !important;
}

.alert {
    border-radius: 1.2rem;
    padding: 15px;
}

.page-wrapper .page-header {
    max-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 8;
    -webkit-transition: .5s;
    transition: .5s;
    background-color: #fff;
    -webkit-box-shadow: 0px 4px 40px rgb(0 0 0 / 7%);
    box-shadow: 0px 4px 40px rgb(0 0 0 / 7%);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #f8f8f8;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgb(235 217 217 / 15%);
    border-radius: 1rem;
    box-shadow: 0 5px 20px hsl(0deg 5% 20% / 10%);
}

.card .card-header {
    padding: 20px 40px 20px 40px;
}

.card .card-body {
    padding: 20px 40px 20px 40px;
    background-color: transparent;
}

//untuk style bc footer paging
.ngx-datatable .datatable-footer .datatable-footer-inner {
    background: white;
    color: black;
 }

 //untuk style icon di table
 .linkAction {
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 8px;
    margin-right: 5px;
    margin-bottom: 30px;
    border-radius: 10px;
}

//untuk style paging
.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 24px;
    line-height: 25px;
    padding: 0;
    border-radius: 3px;
    margin: 0 3px;
    text-align: center;
    vertical-align: top;
    text-decoration: none;
    vertical-align: bottom;
    color: black;
}

//untuk style kotak pada paging
.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
    background-color: lightgray;
    font-weight: bold;
}


// overwride card header
.card .card-header {
    padding: 20px 40px 20px 40px;
}

.card .card-body {
    padding: 20px 40px 20px 40px;
    background-color: transparent;
}


.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    font-size: 1rem;
    font-weight: 400;
    color: #495057;
}

.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
}


.ng-select.is-invalid.ng-touched .ng-select-container {
    border-color: #dc3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #dc3545;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

 .ng-select.is-invalid.ng-untouched .ng-select-container {
    border-color: #dc3545;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #dc3545;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/></svg>");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .ng-select.is-valid.ng-touched .ng-select-container {
    border-color: #28a745;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #28a745;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .ng-select.is-valid.ng-untouched .ng-select-container {
    border-color: #28a745;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 1px #28a745;
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

